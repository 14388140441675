import React from 'react'
import { Link } from 'react-router-dom'

export const ButtonOutline = (props) => {
    const {backgroundColor = "white", onClick, link= ""} = props
    console.log(link);
    
    return (
        <div className="btn-container" style={{backgroundColor}}>
          <Link to={link} onClick={onClick} className="btn-principal" role="button">
              {props.children}
          </Link>
        </div>
    )
}

// const hole = (mode) => {
//     switch (mode) {
//         case "primary":
//             return {
//                 backgroundColor: "white",
//                 color: "black",
//                 lineColor: ""
//             };
    
//         default:
//             break;
//     }
// }