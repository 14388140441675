import React, { useEffect, useRef, useState } from 'react'
// import { API, graphqlOperation } from "aws-amplify";
// import {listVodAssets, listByTag} from "./graphql/queries"
import VideoPlayer from './VideoPlayer';
import barrios from "./assets/barrio_vereda_norm.json"
import informacion from "./assets/fichas-modificada.json"
import {sample} from "lodash"
// import awsvideoconfig from "./aws-video-exports"
import Modal from 'react-modal';
import * as _ from "underscore"
import { ButtonOutline } from './ButtonOutline';
// import { useReactToPrint } from 'react-to-print';
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  function create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c==='x' ? r :((r&0x3)|0x8)).toString(16);
    });
    return uuid;
}
const barrios_id = barrios.filter(barrio => barrio.comuna === "Manrique").map(barrio => {
    return {...barrio, uuid: create_UUID()}
})
export const PhotoMap = (props) => {
    const componentRef = useRef();
    // const handlePrint = () => {
    //     html2canvas(componentRef.current).then(canvas => {
    //         const dataURL = canvas.toDataURL();
    //         console.log(dataURL)
    //     })
    // };
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });

    let cowCallbacks = useRef([])
    // var converter = geojson2svg({
    //     attributes: ['properties.NOMBRE', 'properties.NOMBRE_COMUNA_CORREGIMIENTO', 'properties.CODIGO'],
    //     viewportSize: {width: 400, height: 400},
    //     mapExtent: {
    //     left: -8415000,
    //     right: -8409000,
    //     bottom: 701000.2023406147,
    //     top: 702000.1563653704
    // }
    //  });
    const [barrio, setBarrio] = useState("");
    const [significado, setSignificado] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [quantity, setQuantity] = useState(10);
    // const [raws, setRaws] = useState([]);
    const [videos, setVideos] = useState([])
    const [tags, setTags] = useState([])
    // const [rawtags, setRawTags] = useState([])
    // const [cowCallbacks, setcowCallbacks] = useState([]);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    function handleAdd(ev) {
        ev.preventDefault()
        console.log(significado)
        const video = sample(informacion.filter(ino => ino.simbolico.includes(significado)))
        // const videoF =  raws.filter(raw => raw.video.id === videoID)[0]
        console.log(video)
        video.barrio = JSON.parse(barrio)
        console.log(video);
        setVideos([...videos, video])

        closeModal()
    }   

    
    // const barrios2 = {...barrios, features: barrios["features"].filter(feature => feature.properties["NOMBRE_COMUNA_CORREGIMIENTO"] === "Manrique")}
    // var reproject = require('reproject-spherical-mercator');
    // const normalize = require(' ');
    // var reprojected = reproject(barrios2);
    // // console.log(reprojected)
    // var svgStr = converter.convert(reprojected, {
    //     output: "path", 
    //     }).map(svgitem => {
    //         // console.log(svgitem)       
    //         var segments = normalize({
    //             path: svgitem,
    //             min: 0,
    //             max: 1,
    //             asList: false
    //           })
    //         return segments
    //     })
    
    // console.log(svgStr)
    
    useEffect(() => {
        async function fetchData() {
            console.log("Videos loaded")
            // const result = await API.graphql(graphqlOperation(listVodAssets))
            // const query_raw = result.data.listVodAssets.items
            // setRaws(query_raw)

            // const raw_tags = await API.graphql(graphqlOperation(listByTag, {type: "SIMBOLICO"}))
            const tags = _.unique(_.flatten(informacion.map(ino => ino.simbolico.split(", ")))) 
            // setRawTags(raw_tags.data.listByTag.items)
            console.log(tags);
            setTags(tags)
        }
        fetchData()
    }, [])
    const changeVideos = (quant) => {
        console.log("cambiada cantidad", quant)
        const barrios_manrique = barrios_id.filter(barrio=> barrio.comuna === "Manrique")
        // const sample = [...Array(quant).keys()].map(im => [...informacion][(Math.random()*informacion.length)|0])
        var samplw = _.sample([...informacion], quant).map(ino => {
            return {...ino, barrio: barrios_manrique[(Math.random()*barrios_manrique.length)|0]}
        })
        console.log("cargada informacion", sample);
        if(quant < cowCallbacks.current.length) {
            cowCallbacks.current = cowCallbacks.current.slice(0, quant + 1)
        }
        // cowCallbacks.current = []
        // console.log(raws)
        if (quant === 0) samplw = []
        setVideos([...samplw])
    }
    useEffect(() => {
        changeVideos(quantity)
    }, [quantity])

    // const cowMounted =  (callbacks) => {
    //     console.log("new callback", [callbacks, ...cowCallbacks.current])
    //     cowCallbacks.current.push(callbacks)
    // }

    const changeCow = () => {
        console.log(cowCallbacks)
        changeVideos(quantity)
        console.log(videos);
        // setQuantity(quantity+1 )
        // for (let i in cowCallbacks.current) {
        //     cowCallbacks.current[i].genPosition();
        // }
        
    }
    return [(
        <div className="photomap-container"  ref={componentRef}>
            
            {videos.map((video, index) => {
                // console.log(`https://${awsvideoconfig.awsOutputVideo}/${video.video.id}/${video.video.id}_325.m3u8`)
                // const source = {
                //     src: `${process.env.PUBLIC_URL}/${video.cortometraje}/${video.nombre}.mp4`,
                //     // type: 'application/x-mpegURL'
                //     type: "video/mp4"
                //     }
                console.log(video);
                return (
                        <VideoPlayer
                            key={video.nombre} 
                            autoplay={true}
                            loop
                            style={{ clipPath: `url(#${video.barrio.uuid})` }}
                            // onMounted={callbacks => cowMounted(callbacks)}
                            srco={`${process.env.PUBLIC_URL}/${video.cortometraje}/${video.nombre}.mp4`}
                            />
                        )
            })}
            
            <svg height="0" width="0">
                <defs>
                    {barrios_id.map((svgs, index) => {
                        return (
                            <clipPath key={`${svgs.uuid}`} id={`${svgs.uuid}`} clipPathUnits="objectBoundingBox">
                                <path d={svgs.path}></path>
                            </clipPath>
                        )
                    })}

                </defs>
            </svg>
            
        </div>), (
            <div className="map-actions">
                {/* <ButtonOutline className="btn-principal" onClick={handlePrint}>Guardar</ButtonOutline> */}
                <select className='quantity-change' onChange={(ev) => setQuantity(parseInt(ev.target.value))}>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>30</option>
                    <option>0</option>
                </select>
                {/* <input type="number" max="15" onChange={(ev) => (parseInt(ev.target.value) <= 15 ?  setQuantity(parseInt(ev.target.value)): 15)} /> */}
                <div style={{backgroundColor: 'white'}} className='btn-container'><button className="btn-principal" onClick={() => changeCow()}>Generar nuevo</button></div>
                <div style={{backgroundColor: 'white'}} className='btn-container'><button className="btn-principal" onClick={openModal}>Añadir</button></div>
            </div>
        ),
    (<Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Añadir nuevo video"
      >
        <h2>Añade un nuevo video</h2>
        {/* <button onClick={closeModal}>close</button> */}
        <form>
            <div>
          <label>Selecciona un barrio</label>
          <select name="barrios" onChange={(ev) => setBarrio(ev.target.value)}>
              {barrios_id.map(barrio => {
                  return (<option value={JSON.stringify(barrio)}>{barrio.name}</option>)
              })}
          </select>
          </div>
          <div>
          <label>Selecciona un significado</label>
          <select name="significados" onChange={(ev) => setSignificado(ev.target.value)}>
              {tags.map(tag => {
                  return (<option>{tag}</option>)
              })}
          </select>
          </div>
          <ButtonOutline className="btn-principal" onClick={handleAdd}>Añadir video</ButtonOutline>
        </form>
      </Modal>)]
    
}
